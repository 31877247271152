<template>
    <sui-message success>
        <sui-message-header>New User Created</sui-message-header>
        <ul>
            <li>Default password: <b>{{defaultPassword}}</b></li>
            <li>Invitation Link: <b><a :href="invitationLink">{{invitationLink}}</a></b></li>
        </ul>
    </sui-message>
</template>

<script>
    export default {
        name: "SuccessNewUserMessage",
        props: {
            invitationLink: String,
            defaultPassword: String,
        }
    }
</script>

<style scoped>

</style>