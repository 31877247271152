<template>
    <div id="add-user">
        <h2 is="sui-header" class="dividing">Add User</h2>

        <sui-form :loading="loading" :error="errorTitle !== ''" :success="defaultPassword !== ''">
            <success-new-user-message v-if="defaultPassword !== ''" :invitation-link="invitationLink"
                                      :default-password="defaultPassword"></success-new-user-message>
            <error-message :title="errorTitle" :reasons="errorList"></error-message>
            <access-form :user="user"></access-form>

            <sui-divider></sui-divider>

            <sui-button type="submit" color="blue" @click.prevent="save">Create User</sui-button>
            <sui-button floated="right" @click.prevent="cancel">Cancel</sui-button>
        </sui-form>
    </div>
</template>

<script>
    import axios from "axios";
    import AccessForm from "@/views/dashboard/access/AccessForm";

    import SuccessNewUserMessage from "@/components/SuccessNewUserMessage";
    import ErrorMessage from "@/components/ErrorMessage";

    export default {
        name: "AccessAdd",
        components: {SuccessNewUserMessage, ErrorMessage, AccessForm},
        created: function () {
            console.log(this.$currentUser);
        },
        data: function () {
            return {
                user: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    contactNumber: "",
                    alternateContactNumber: "",
                    company: "",
                    jobTitle: "",
                    street: "",
                    city: "",
                    zipCode: "",
                    country: "",
                    state: "",
                    status: "Active",
                    associatedRoles: "SG",

                    errorFirstName: false,
                    errorLastName: false,
                    errorEmail: false,
                },
                loading: false,

                invitationLink: "",
                defaultPassword: "",

                errorTitle: "",
                errorList: [],
            }
        },
        methods: {
            cancel: function () {
                this.$router.push('/dashboard/access');
            },
            save: async function () {
                if (this.user.firstName.trim() !== "" && this.user.lastName.trim() !== "" && this.user.email.trim() !== "") {
                    try {
                        const response = await axios({
                            method: "POST",
                            url: "/api/user/create",
                            headers: {
                                "Content-Type": "application/json; charset=utf-8",
                            },
                            data: {
                                token: this.$cookies.get('token'),
                                tokenId: this.$cookies.get('tokenId'),

                                email: this.user.email,
                                firstName: this.user.firstName,
                                lastName: this.user.lastName,
                                status: this.user.status,
                                role: this.user.associatedRoles,
                                metadata: {
                                    contactNumber: this.user.contactNumber,
                                    alternateContactNumber: this.user.alternateContactNumber,
                                    company: this.user.company,
                                    jobTitle: this.user.jobTitle,
                                    street: this.user.street,
                                    city: this.user.city,
                                    zipCode: this.user.zipCode,
                                    country: this.user.country,
                                    state: this.user.state,
                                },
                            }
                        });

                        console.log(response.data);
                        if (response.data && response.data.userId) {
                            this.defaultPassword = response.data.defaultPassword;
                            this.invitationLink = this.$config.webServer + "register/" + response.data.invitationCode;
                            this.errorTitle = "";
                            this.errorList = [];
                            this.user.errorFirstName = false;
                            this.user.errorLastName = false;
                            this.user.errorEmail = false;
                        } else {
                            this.defaultPassword = ""
                            this.invitationLink = ""
                            this.errorTitle = "Cannot create new user";
                            this.errorList = [];
                            this.errorList.push("User exists in the database.");
                        }
                    } catch (e) {
                        this.defaultPassword = ""
                        this.invitationLink = ""
                        this.errorTitle = "Cannot create new user";
                        this.errorList = [];
                        this.errorList.push("Network connection issue.");
                    }

                } else {
                    this.user.errorFirstName = this.user.firstName.trim() === "";
                    this.user.errorLastName = this.user.lastName.trim() === "";
                    this.user.errorEmail = this.user.email.trim() === "";
                }
            }
        }
    }
</script>

<style scoped>

</style>
